const config = {
  //配置默认语言
  Language: "en",
  // Language :'tc',
  //ApiUrl: "https://h0760.com",
  // ApiUrl: "http://newfootball.xyz",
  ApiUrl: "https://gtstek.com",
  baseUrl:"gtstek.com"
};
export default config;

