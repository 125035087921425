const tab = {
  text: [
    '首页',
    '足球',
    '注单',
    '投注记录'
  ]
}
const dialog = [
  "提示",
  "確定",
  "正在提交...",
  "複製成功",
  "IOS系統版本低不支持",
  "正在註冊...",
  "數據加載中...",
   "没有更多了"
]

const index = {
  title: '早盘',
  title2: '滚球',
  zuqiu: '足球',
  Alltime: '所有时间',
  timeAndPlay: '时间/联赛'
}
const login = {
  accont: '登录账号',
  phone: '手机号',
  mail: '邮箱',
  pwd: '密码',
  placeholder1: '请输入手机号',//'
  placeholder_m: '请输入邮箱',//'
  placeholder2: '请输入账号',//'Please enter your accont',
  placeholder3: '请输入密码',//'Please enter a password',
  placeholder4: '请输入验证码',//'Please enter a password',
  placeholder5: '请输入邀请码',//Please enter the recommendation code
  forgotPwd: '忘记密码？',
  forgotPwd1: '忘记密码',
  Reset_password: '重置密码',
  login: '登录',
  Dont_have_an_account_yet: '还没账户？',
  yklogin: '游客登录',
  zxkf: '在线客服',
  remember: '记住我的帐号',
  Register_now: '立即注册',
  Login_now: '前往登录',
  Register: '注册',
  VerificationCode: '验证码',
  ConfirmPassword: '确认密码',
  getCode: '验证码',
  send: '已发送',
  Referral_Code: '邀请码',
  by: '点击注册表示我已阅读并同意',
  User_Agreement: '用户协议',
  submit: '确认',

  read1: '我已阅读并同意',
  read2: '相关条款',
  read3: '和',
  read4: '隐私政策',
}

const lang = {
  text: '语言',
}

const recharge = {
  rechargeLog: '充值记录',
  title: 'USDT充值页面',
  money: '金额',
  btn1: '扫描二维码充值后提交',
  recharge: '充值',
  ERC20: 'ERC20',
  TRC20: 'TRC20',
  OMNI: 'OMNI',
  text: [
    'This address can only deposit USDT, deposits other than USDl are invalid and non - refundable',
    'TKQeybLDtotxGMxaEpROWgESjTtvGqH98L'
  ],
  copy: 'Copy',
  label: [
    "类别",
    "地址",
    "充值金额",
    "输入金额",
    "上传凭证",
    "拷贝成功",
    "交易所"
  ],
  default: [
    "钱包充值",
    "Wallet pre-recharge",
    "Recharge method",
    "Recharge",
    "confirm",
  ],
  coinRecharge: [
    '虚拟币充值', '币种', '钱包地址', "地址二维码"
  ],

  balanceNotEnough:'余额不足',
  balanceTip:"请去充值"
}

const withdraw = {
  withdrawLog: '提现记录',
  withdraw: '提现',
  AccountBalance: '账户余额',
  FreezeBalance: '冻结余额',
  WithdrawalAddress: '提款地址',
  ChainName: '链名称',
  WithdrawalAmount: '提款金额',
  TransactionPassword: '交易密码',
  p1: '请输入提款地址',
  p2: '请输入提款金额',
  p3: '请输入交易密码',
}

const my = {
  text: [
    '帐户历史',
    '额度修改记录',
    '语言',
    '盘口数据',
    '修改密码',
    '简易密码登录',
    '密码恢复',
    '常见问题',
    '赔率转换',
    '系统需求',
    '规则与条款',
    '联系我们',
  ],
  text1: [
    '激活'
  ],
  setting: '设定',
  help: '帮助',
  logout: '登出'
}

const zuqiu = {
  text: [
    '时间',
    '联赛',
    '赛事筛选',
    '让球',
    '大小','半场','所有盘口'
  ],
  hands:{
    'mh':'香港盘',
    'wb':'香港盘2'
  },
  wanfa: [
    '滚球',
    '早盘',
  ],
  qiu: [
    '足球',
    '篮球',
    '电竞足球',
    '网球',
    '羽毛球',
  ],
  type: [
    '主要玩法',
    '让球&大小',
    '角球',
    '罚牌数',
    '波胆',
    '进球球员',
  ],
  text1: [
    '让球',
    '得分大小',
    '独赢',
  ],
  big: '大',
  small: '小',
  text2: [
    '主',
    '客',
    '和',
  ],
  zhudan: '注单',
  xiazhu: '下注',
  month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  month_s: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  week: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday',],
}

const order = {
  type: [
    '交易状况',
    '数据统计',
  ],
  allTy: '所有体育',
  money1: '投注金额',
  money2: '可赢金额',
  all: '总共',
  removeAll: '确定移除所有投注?',
  table_title: [
    '日期',
    '投注金额',
    '有效金额',
    '当前余额',
  ],
  tzlog:["联赛","比赛","订单号","投注金额","投注项","投注内容","投注类型","赔率","中奖金额","状态","下注时间"],
}

const changePwd = {
  text: [
    '为了安全起见，您必须每30天改变一次密码。',
    '您的密码必须根据以下规则:',
    '1、至少要有两个大或小写英文字母和数字(0 - 9)组合, 字数最少6至12个。',
    '2、三个不同的字母数字。',
    '3、不准许有空格。',
  ],
  changePwd: '修改密码',
  now_pwd: '当前密码',
  new_pwd: '新密码',
  sure_pwd: '确认密码',
  btn1: '取消',
  btn2: '提交',
}

const simplePwd = {
  simplePwd: '简易密码登录',
  text: [
    '输入您的简易密码',
    '设定您的简易密码以便快速登入。',
    '关闭',
    '删除',
  ]
}

const resetPwd = {
  'resetPwd': '密码恢复',
  'text': [
    '请输入您用于恢复密码的电子信箱地址。',
    '电子邮箱地址',
    '取消',
    '下一步',
  ]
}

const question = {
  question: '常见问题',
  time: '最后更新日期:'
}

const odds = {
  odds: '赔率转换',
  table_title1: [
    '盘口类型',
    '投注金额',
    '赔率',
    '赢',
    '输',
  ],
  title: '赔率转换表格',
  table_title2: [
    '香港盘',
    '马来盘',
    '印尼盘',
  ]
}

const system = {
  system: '系统需求',
  text1: [
    '手机操作系统',
    '操作系统',
    '需求',
    '安卓',
    '安卓7或以上',
    'IOS',
    'IOS12或以上',
  ],
  text2: [
    '手机浏览器',
    '安卓浏览器',
    '需求',
    'chrome',
    '版本87或以上',
    'QQ浏览器',
    '版本10或以上',
  ],
  text3: [
    'IOS浏览器',
    'IOS浏览器',
    '需求',
    'safari',
    '版本11或以上',
    'chrome',
    '版本84或以上',
  ],
  text4: [
    '桌面操作系统',
    '操作系统',
    '需求',
    'windows',
    'windows 7或是10',
    'Mac OS',
    'OS X10.10或以上',
  ],
  text5: [
    '桌面浏览器',
    'windows浏览器',
    '需求',
    'chrome',
    '版本85或以上',
    '火狐',
    '版本60或以上',
  ],
  text6: [
    '不支援的浏览器和操作系统',
    '为了提供最佳的用户体验，我们将保持所支援的浏览器或操作系统的清单简短。如果有漏洞或错误出现在那些我们所没有支援的浏览器或操作系统里面，我们保留不约予修正的权利。倘若您还是坚持使用那些不被我们所支援的浏览器或操作系统，您可能会遇到系统的效能问题或关于画面上的问题。'
  ]
}

const type = {
  type: [
    '全部',
    '进行中',
    '失败',
    '成功',
  ]
}


const rule = {
  rule: '规则与条款',
  text1: [
    '使用条款',
    '1.享用本公司提供的服务是客户本人的意愿，其风险应由户本人承担。参与我们服务的同时就说明客户认同本公司所提供的服务是正常、合理、公正、公平的。<br><br>2.某些法律规则在贵国居住的司法管辖区; 并未明文规定在线和非在线博彩是否允许，客户有责任确保任何时候您的博彩行为在您所属地是属合法行为。'
  ],
  text2: [
    '投注接受条件',
    '1.享用本公司提供的服务是客户本人的意愿，其风险应由户本人承担。参与我们服务的同时就说明客户认同本公司所提供的服务是正常、合理、公正、公平的。<br><br>2.某些法律规则在贵国居住的司法管辖区; 并未明文规定在线和非在线博彩是否允许，客户有责任确保任何时候您的博彩行为在您所属地是属合法行为。'
  ],
  text3: [
    '免责条款',
    '1.享用本公司提供的服务是客户本人的意愿，其风险应由户本人承担。参与我们服务的同时就说明客户认同本公司所提供的服务是正常、合理、公正、公平的。<br><br>2.某些法律规则在贵国居住的司法管辖区; 并未明文规定在线和非在线博彩是否允许，客户有责任确保任何时候您的博彩行为在您所属地是属合法行为。'
  ],
}

const contactUs = {
  contactUs: '联系我们'
}

const tzLog = {
  type: [
    '未结算',
    '已结算',
    '预约投注',
  ]
}
const detail = {
  text: [
    '暂无直播'
  ],
  type: [
    '全部',
    '热门',
    '让球&大/小',
    '波胆',
    '进球',
    '特殊',
  ]
}
export {
  detail,
  tzLog,
  dialog,
  withdraw,
  tab,
  index,
  login,
  lang,
  recharge,
  my,
  zuqiu,
  order,
  changePwd,
  simplePwd,
  resetPwd,
  question,
  odds,
  system,
  rule,
  contactUs,
  type
}
